<template>
  <div id="app">
    <div v-if="!isDone">
      <header class="header">
        <div class="header__stepper">
          <step-nav :step="activeStep" :steps="route.length" />
        </div>
      </header>
      <div class="planer">
        <ValidationObserver v-slot="{ passes }">
          <form @submit.prevent="passes(nextStep)" class="form">
            <div class="step">
              <header class="step__header">
                <h2>{{ currentStep.title }}</h2>
              </header>
              <div class="fields">
                <ValidationProvider
                  v-for="(field, i) in currentFields"
                  :key="`${field.name}-${i}`"
                  :name="field.name"
                  :rules="field.rules"
                  v-slot="{ errors }"
                  persist
                  class="field"
                  :class="field.classes"
                >
                  <transition appear name="list" tag="div">
                    <!-- Info -->
                    <div v-if="field.type === 'info'" class="info">
                      <p>{{ field.value }}</p>
                    </div>
                    <!-- Radio -->
                    <div v-if="field.type === 'radio'" class="radios">
                      <h4>{{ field.name }}</h4>
                      <label
                        v-for="item in field.values"
                        :key="item.value"
                        class="radio"
                      >
                        <input
                          v-model="$data['store'][field.value]"
                          type="radio"
                          :value="item.value"
                        />
                        <div class="radio__inner">
                          {{ item.value }}
                          <span class="radio__desc" v-if="item.desc">
                            {{ item.desc }}
                          </span>
                        </div>
                      </label>
                      <span v-if="errors[0]" class="error">{{
                        errors[0]
                      }}</span>
                    </div>
                    <!-- Checkbox -->
                    <div v-if="field.type === 'checkbox'">
                      <label class="checkbox">
                        <input
                          v-model="$data['store'][field.value]"
                          type="checkbox"
                        />
                        <div class="checkbox__inner">
                          {{ field.name }}
                          <small class="checkbox__desc" v-if="field.desc">
                            {{ field.desc }}
                          </small>
                        </div>
                      </label>
                      <span v-if="errors[0]" class="error">{{
                        errors[0]
                      }}</span>
                    </div>
                    <!-- Text -->
                    <div v-if="field.type === 'text'">
                      <label class="label">{{ field.name }}</label>
                      <input
                        class="text"
                        v-model="$data['store'][field.value]"
                        type="text"
                        :placeholder="field.name"
                      />
                      <span v-if="errors[0]" class="error">{{
                        errors[0]
                      }}</span>
                    </div>
                    <!-- Date -->
                    <div v-if="field.type === 'date'">
                      <label class="label">{{ field.name }}</label>
                      <flat-pickr
                        placeholder="Datum und Uhrzeit"
                        class="text"
                        :config="dateConfig"
                        v-model="$data['store'][field.value]"
                      ></flat-pickr>
                      <span v-if="errors[0]" class="error">{{
                        errors[0]
                      }}</span>
                    </div>
                    <!-- Time -->
                    <div v-if="field.type === 'time'">
                      <label class="label">{{ field.name }}</label>
                      <flat-pickr
                        placeholder="Uhrzeit"
                        class="text"
                        :config="timeConfig"
                        v-model="$data['store'][field.value]"
                      ></flat-pickr>
                      <span v-if="errors[0]" class="error">{{
                        errors[0]
                      }}</span>
                    </div>
                    <!-- Number -->
                    <div v-if="field.type === 'number'">
                      <label class="label">{{ field.name }}</label>
                      <div class="number__inner">
                        <button
                          tabindex="-1"
                          @click="decrease(field.value)"
                          class="number__btn btn"
                          type="button"
                        >
                          -
                        </button>
                        <input
                          oninput="this.value = Math.abs(this.value)"
                          min="0"
                          class="number"
                          v-model="$data['store'][field.value]"
                          type="number"
                          :placeholder="field.name"
                        />
                        <button
                          tabindex="-1"
                          @click="increase(field.value)"
                          class="number__btn btn"
                          type="button"
                        >
                          +
                        </button>
                      </div>
                      <span v-if="errors[0]" class="error">{{
                        errors[0]
                      }}</span>
                    </div>
                    <!-- Review -->

                    <div class="review" v-if="field.type === 'review'">
                      <h3 class="review__title">
                        Übersicht Deiner Eventanfrage
                      </h3>
                      <p>
                        Du möchtest am <strong>{{ date_formated }}</strong> um
                        <strong>{{ time_formated }}</strong> mit
                        <strong>{{ persons }}</strong> Personen kommen
                      </p>
                      <table class="table">
                        <tbody>
                          <tr>
                            <th>Posten</th>
                            <th>Anzahl</th>
                            <th>Preis</th>
                            <th>Gesamt</th>
                          </tr>

                          <!-- Eintritt -->

                          <tr v-if="store.adults > 0">
                            <th>Eintritt (Erwachsene)</th>
                            <th>{{ store.adults }}</th>
                            <th v-if="persons >= 20">
                              <s>{{ fee.adults.regular | toEur }}</s>
                              {{ fee.adults.discount | toEur }}
                            </th>
                            <th v-else>
                              {{ fee.adults.regular | toEur }}
                            </th>
                            <th>{{ price.adults | toEur }}</th>
                          </tr>
                          <tr v-if="store.kids > 0">
                            <th>Eintritt (Kinder)</th>
                            <th>{{ store.kids }}</th>
                            <th v-if="persons >= 20">
                              <s>{{ fee.kids.regular | toEur }}</s>
                              {{ fee.kids.discount | toEur }}
                            </th>
                            <th v-else>
                              {{ fee.kids.regular | toEur }}
                            </th>
                            <th>{{ price.kids | toEur }}</th>
                          </tr>

                          <!-- Bereiche (Grillplätze + Catering) -->

                          <tr v-if="store.bbq">
                            <td>Grillplatz</td>
                            <td>1</td>
                            <th colspan="2" v-if="store.bbq_menu == 'Catering'">
                              inklusive
                            </th>
                            <th
                              colspan="2"
                              v-if="store.bbq_menu == 'Selbst grillen'"
                            >
                              {{ fee.bbq.selfservice.rent | toEur }}
                            </th>
                          </tr>

                          <tr v-if="store.beach">
                            <td>Strandbar</td>
                            <td>1</td>
                            <th colspan="2">
                              individuell
                            </th>
                          </tr>

                          <tr
                            v-if="
                              store.bbq_menu == 'Selbst grillen' && store.bbq
                            "
                          >
                            <td>Mindestverzehr</td>
                            <td>1</td>
                            <th colspan="2">
                              {{ fee.bbq.selfservice.drinks | toEur }}
                            </th>
                          </tr>

                          <tr v-if="store.bbq_menu == 'Catering'">
                            <td>Catering</td>
                            <td>{{ persons }}</td>
                            <th v-if="store.catering_special">
                              {{ fee.bbq.catering.special | toEur }}
                            </th>
                            <th v-else>
                              {{ fee.bbq.catering.regular | toEur }}
                            </th>
                            <th>
                              {{ bbq_price | toEur }}
                            </th>
                          </tr>

                          <!-- Menüs (Kindergeburtstag) -->

                          <tr v-if="kids_menu_price > 0">
                            <td>{{ store.kids_menu_type }}</td>
                            <td>{{ store.kids_menu }}</td>
                            <th>
                              {{ fee.kids_menu.regular | toEur }}
                            </th>
                            <th>
                              {{ kids_menu_price | toEur }}
                            </th>
                          </tr>

                          <!-- Klettern -->

                          <tr
                            v-if="store.klettern && store.klettern_adults > 0"
                          >
                            <th>Klettern (Erwachsene)</th>
                            <th>{{ store.klettern_adults }}</th>
                            <th v-if="persons >= 10">
                              <s>{{ fee.klettern.adults.regular | toEur }}</s>
                              {{ fee.klettern.adults.discount | toEur }}
                            </th>
                            <th v-else>
                              {{ fee.klettern.adults.regular | toEur }}
                            </th>
                            <th>
                              {{ klettern_price.adults | toEur }}
                            </th>
                          </tr>

                          <tr
                            v-if="store.klettern && store.klettern_reduced > 0"
                          >
                            <th>Klettern (Erwachsene)</th>
                            <th>{{ store.klettern_reduced }}</th>
                            <th v-if="persons >= 10">
                              <s>{{ fee.klettern.reduced.regular | toEur }}</s>
                              {{ fee.klettern.reduced.discount | toEur }}
                            </th>
                            <th v-else>
                              {{ fee.klettern.reduced.regular | toEur }}
                            </th>
                            <th>
                              {{ klettern_price.reduced | toEur }}
                            </th>
                          </tr>

                          <tr v-if="store.klettern && store.klettern_kids > 0">
                            <th>Klettern (Kinder)</th>
                            <th>{{ store.klettern_kids }}</th>
                            <th v-if="persons >= 10">
                              <s>{{ fee.klettern.kids.regular | toEur }}</s>
                              {{ fee.klettern.kids.discount | toEur }}
                            </th>
                            <th v-else>
                              {{ fee.klettern.kids.regular | toEur }}
                            </th>
                            <th>{{ klettern_price.kids | toEur }}</th>
                          </tr>

                          <!-- Minigolf -->

                          <tr
                            v-if="store.minigolf && store.minigolf_adults > 0"
                          >
                            <th>Minigolf (Erwachsene)</th>
                            <th>{{ store.minigolf_adults }}</th>
                            <th v-if="persons >= 10">
                              <s>{{ fee.minigolf.adults.regular | toEur }}</s>
                              {{ fee.minigolf.adults.discount | toEur }}
                            </th>
                            <th v-else>
                              {{ fee.minigolf.adults.regular | toEur }}
                            </th>
                            <th>
                              {{ minigolf_price.adults | toEur }}
                            </th>
                          </tr>

                          <tr v-if="store.minigolf && store.minigolf_kids > 0">
                            <th>Minigolf (Kinder)</th>
                            <th>{{ store.minigolf_kids }}</th>
                            <th v-if="persons >= 10">
                              <s>{{ fee.minigolf.kids.regular | toEur }}</s>
                              {{ fee.minigolf.kids.discount | toEur }}
                            </th>
                            <th v-else>
                              {{ fee.minigolf.kids.regular | toEur }}
                            </th>
                            <th>{{ minigolf_price.kids | toEur }}</th>
                          </tr>

                          <!-- Volleyball -->

                          <tr v-if="store.volleyball">
                            <td>Volleyballplätze</td>
                            <td>{{ store.volleyball_fields }}</td>
                            <td>{{ fee.volleyball.regular | toEur }}</td>
                            <td>{{ volleyball_price | toEur }}</td>
                          </tr>

                          <!-- Bogenschießen -->

                          <tr v-if="store.bogenschiessen">
                            <td>Bogenschießen</td>
                            <td>1</td>
                            <td colspan="2">individuell</td>
                          </tr>

                          <!-- Teambuilding -->

                          <tr v-if="store.teambuilding">
                            <td>Teambuilding</td>
                            <td>1</td>
                            <td colspan="2">individuell</td>
                          </tr>

                          <!-- Eiegne Spiele -->

                          <tr v-if="store.eigeneSpiele">
                            <td>Spielwiese</td>
                            <td>1</td>
                            <td colspan="2">inklusive</td>
                          </tr>
                        </tbody>
                      </table>
                      <div style="text-align: right; padding-top: 1em;">
                        <span
                          style="border-bottom: double 3px #fff; margin-top: 1em;"
                        >
                          {{ price.all | toEur }}
                        </span>
                      </div>
                    </div>
                  </transition>
                </ValidationProvider>
              </div>
            </div>

            <div class="footer">
              <div class="footer__inner">
                <div class="footer__price">{{ price.all | toEur }}</div>
                <div>
                  <button
                    class="btn ghost"
                    v-if="activeStep > 0"
                    type="button"
                    @click="activeStep--"
                  >
                    Zurück
                  </button>
                  <button :disabled="isSending" class="btn">
                    {{ isLastStep ? "Anfrage abschicken" : "Weiter ➝" }}
                  </button>
                </div>
              </div>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>
    <div v-else>
      <div class="success">
        <div class="success__inner">
          <header class="step__header">
            <h2>Super, Anfrage erhalten!</h2>
          </header>
          <p>
            Wir bearbeiten deine Anfrage so schnell wie möglich und melden uns
            dann bei dir!
          </p>
          <br />
          <a class="btn" href="https://www.seaside-beach.de/">Zurück</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ValidationObserver, ValidationProvider } from "vee-validate";
import StepNav from "./components/StepNav.vue";
import moment from "moment";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import { German } from "flatpickr/dist/l10n/de.js";

export default {
  name: "App",
  data() {
    return {
      isDone: false,
      isSending: false,
      dateConfig: {
        altFormat: "j F Y – H:i",
        altInput: true,
        enableTime: true,
        locale: German,
        disable: ["2021-09-11"],
        dateFormat: "U",
        time_24hr: true,
      },
      timeConfig: {
        enableTime: true,
        locale: German,
        noCalendar: true,
        dateFormat: "H:i",
        time_24hr: true,
        minTime: "10:00",
        maxTime: "23:00",
        allowInput: true,
        // locale: Hindi, // locale for this instance only
      },
      //Store data for field
      store: {
        event_type: null,
        adults: 1,
        kids: 0,
        reduced: 0,
        companion: 0,
        datetime: "",
        endtime: "",
        bbq: false,
        beach: false,
        bbq_menu: "",
        catering_special: false,
        // Menu
        kids_menu_type: "Kein Menü",
        kids_menu: 1,
        // Activities
        volleyball: false,
        volleyball_fields: 1,
        volleyball_start: "",
        volleyball_end: "",
        bogenschiessen: false,
        bogenschiessen_start: "",
        bogenschiessen_end: "",
        klettern: false,
        klettern_adults: 0,
        klettern_kids: 0,
        klettern_reduced: 0,
        klettern_start: "",
        klettern_end: "",
        minigolf: false,
        minigolf_adults: 0,
        minigolf_kids: 0,
        minigolf_start: "",
        minigolf_end: "",
        eigeneSpiele: false,
        eigeneSpiele_start: "",
        eigeneSpiele_end: "",
        teambuilding: false,
        teambuilding_start: "",
        teambuilding_end: "",
        // Personal Data
        name: "",
        email: "",
        company: "",
        phone: "",
      },
      activeStep: 0,
      fee: {
        adults: {
          regular: 5,
          discount: 3,
        },
        reduced: {
          regular: 4,
          discount: 2.5,
        },
        kids: {
          regular: 2.5,
          discount: 2.5,
        },
        minigolf: {
          adults: {
            regular: 2.5,
            discount: 2,
          },
          kids: {
            regular: 2,
            discount: 1.5,
          },
        },
        volleyball: {
          regular: 12,
        },
        klettern: {
          adults: {
            regular: 19.5,
            discount: 18.5,
          },
          reduced: {
            regular: 17.5,
            discount: 16.5,
          },
          school: {
            regular: 15,
            discount: 11.5,
          },
          kids: {
            regular: 13.5,
            discount: 12.5,
          },
        },
        bbq: {
          catering: {
            regular: 36.5,
            special: 40.0,
          },
          selfservice: {
            rent: 90,
            drinks: 75,
          },
        },
        kids_menu: {
          regular: 7.5,
        },
      },
    };
  },
  components: {
    StepNav,
    flatPickr,
    ValidationObserver,
    ValidationProvider,
  },
  mounted() {
    this.getDisabledDates();
  },
  computed: {
    route() {
      // 0 Eventart
      let event_type = {
        title: "Um welches Event gehts?",
        fields: [
          {
            type: "radio",
            name: "Art des Events",
            rules: "required",
            value: "event_type",
            values: [
              {
                value: "Firmen-Event",
              },
              {
                value: "Kindergeburtstag",
              },
              {
                value: "Private Feier",
              },
              {
                value: "Schulklassen",
              },
              {
                value: "Vereine",
              },
              {
                value: "Junggesellenabschiede",
              },
            ],
          },
        ],
      };
      // Besucher
      let event_visitor = {
        title: "Wer kommt uns besuchen?",
        fields: [
          {
            type: "number",
            name: "Erwachsene",
            rules: "required|min_value:1",
            value: "adults",
            classes: "third",
          },
          {
            type: "number",
            name: "Ermäßigt",
            rules: "required",
            value: "reduced",
            classes: "third",
          },
          {
            type: "number",
            name: "Kinder (6-12 J.)",
            rules: "required",
            value: "kids",
            classes: "third",
          },
          {
            type: "info",
            name: "info-entry",
            value:
              "Ermäßigter Eintritt für Schüler, Studenten und Renter. Kinder unter 6 Jahren bezahlen keinen Eintritt",
          },
          {
            type: "date",
            name: "Datum & Startzeit",
            rules: "required",
            value: "datetime",
            classes: "half",
          },
          {
            type: "time",
            name: "Endzeit",
            rules: "required",
            value: "endtime",
            classes: "half",
          },
        ],
      };

      // Besucher
      let event_visitor_companion = {
        title: "Wer kommt uns besuchen?",
        fields: [
          {
            type: "number",
            name: "Begleitperson",
            rules: "required|min_value:1",
            value: "adults",
            classes: "half",
          },
          {
            type: "number",
            name: "Kinder",
            rules: "required",
            value: "kids",
            classes: "half",
          },
          {
            type: "date",
            name: "Datum & Startzeit",
            rules: "required",
            value: "datetime",
            classes: "half",
          },
          {
            type: "time",
            name: "Endzeit",
            rules: "required",
            value: "endtime",
            classes: "half",
          },
        ],
      };

      // Besucher
      let event_visitor_2inkl = {
        title: "Wer kommt uns besuchen?",
        fields: [
          {
            type: "number",
            name: "Erwachsene",
            rules: "required|min_value:1",
            value: "adults",
            classes: "half",
          },
          {
            type: "number",
            name: "Kinder",
            rules: "required",
            value: "kids",
            classes: "half",
          },
          {
            type: "info",
            name: "info-entry",
            value:
              "Für 2 Erwachsene als Begleitpersonen pro Klasse ist der Eintritt kostenfrei",
          },
          {
            type: "date",
            name: "Datum & Startzeit",
            rules: "required",
            value: "datetime",
            classes: "half",
          },
          {
            type: "time",
            name: "Endzeit",
            rules: "required",
            value: "endtime",
            classes: "half",
          },
        ],
      };

      // Menü
      let event_kidsmneu = {
        title: "Kinder-Menüs",
        fields: [
          {
            type: "radio",
            name: "Welches Menü wollt ihr dazubuchen?",
            value: "kids_menu_type",
            values: [
              {
                value: "Kein Menü",
              },
              {
                value: "Currywurst + Pommes",
              },
              {
                value: "Nuggets + Pommes",
              },
            ],
          },
          {
            type: "number",
            name: "Anzahl Menüs",
            rules: "required|min_value:1",
            condition: {
              field: "kids_menu_type",
              operator: "!=",
              value: "Kein Menü",
            },
            value: "kids_menu",
            classes: "full",
          },
        ],
      };
      // Bereiche
      let event_areas = {
        title: "Möchtest ihr einen Grillplatz buchen?",
        fields: [
          {
            type: "info",
            condition: {
              field: this.persons,
              operator: "<",
              value: 30,
            },
            name: "Bitte beachten:",
            value: `Grillplätze können erst ab 30 Personen gebucht werden. (Aktuell: ${this.persons})`,
          },
          {
            type: "checkbox",
            condition: {
              field: this.persons,
              operator: ">=",
              value: 30,
            },
            name: "Grillplatz",
            value: "bbq",
          },
          {
            type: "radio",
            condition: {
              field: "bbq",
              operator: "==",
              value: true,
            },
            name: "Verpflegung",
            rules: "required",
            value: "bbq_menu",
            values: [
              {
                value: "Catering",
                desc: "36,50€ / Person",
              },
              {
                value: "Selbst grillen",
                desc: "90€ + 75€ Mindestverzehr",
              },
            ],
          },
          {
            type: "info",
            condition: {
              field: "bbq_menu",
              operator: "==",
              value: "Selbst grillen",
            },
            name: "Getränkeliste",
            value: "Getränkeliste folgt",
          },
          {
            type: "checkbox",
            condition: {
              field: "bbq_menu",
              operator: "==",
              value: "Catering",
            },
            name: "Blechkuchen und Kaffee",
            rules: "required",
            desc: "+ 3,50 €",
            value: "catering_special",
          },
        ],
      };

      // Bereiche Company
      let event_areas_company = {
        title: "Welche Gastro-Bereiche wollt ihr buchen?",
        fields: [
          {
            type: "info",
            condition: {
              field: this.persons,
              operator: "<",
              value: 30,
            },
            name: "Bitte beachten:",
            value: `Grillplätze können erst ab 30 Personen gebucht werden. Strandbar kann am  (Aktuell: ${this.persons})`,
          },
          {
            type: "info",
            condition: {
              field: this.persons,
              operator: "<",
              value: 50,
            },
            name: "Bitte beachten:",
            value: `Die Standbar kann ab 50 Personen gebucht werden. (Aktuell: ${this.persons})`,
          },
          {
            type: "checkbox",
            condition: {
              field: this.persons,
              operator: ">=",
              value: 50,
            },
            name: "Strandbar",
            value: "beach",
          },
          {
            type: "checkbox",
            condition: {
              field: this.persons,
              operator: ">=",
              value: 30,
            },
            name: "Grillplatz",
            value: "bbq",
          },
          {
            type: "radio",
            condition: {
              field: "bbq",
              operator: "==",
              value: true,
            },
            name: "Verpflegung",
            rules: "required",
            value: "bbq_menu",
            values: [
              {
                value: "Catering",
                desc: "36,50€ / Person",
              },
            ],
          },
          {
            type: "checkbox",
            condition: {
              field: "bbq_menu",
              operator: "==",
              value: "Catering",
            },
            name: "Blechkuchen und Kaffee",
            rules: "required",
            desc: "+ 3,50 €",
            value: "catering_special",
          },
          {
            type: "info",
            condition: {
              field: "bbq_menu",
              operator: "==",
              value: "Selbst grillen",
            },
            name: "Getränkeliste",
            value: "Getränkeliste folgt",
          },
        ],
      };

      // 2 Aktivitäten & Bereiche (+Spielwiese)
      let event_activities_companion = {
        title: "Welche Aktivitäten wollt ihr buchen?",
        fields: [
          {
            type: "checkbox",
            name: "Klettern",
            rules: "",
            value: "klettern",
            desc: "ab 10€ / Person",
          },
          {
            type: "info",
            condition: {
              field: "klettern",
              operator: "==",
              value: true,
            },
            name: "Tipp:",
            value: "Gruppenrabatt ab 10 Teilnehmern",
          },
          {
            type: "number",
            condition: {
              field: "klettern",
              operator: "==",
              value: true,
            },
            rules: `required|max_value:${this.store.adults}`,
            name: "Klettern Teilnehmer (Erwachsen)",
            value: "klettern_adults",
            classes: "third",
          },
          {
            type: "number",
            condition: {
              field: "klettern",
              operator: "==",
              value: true,
            },
            rules: `required|max_value:${this.store.reduced}`,
            name: "Klettern Teilnehmer (Ermäßigt)",
            value: "klettern_reduced",
            classes: "third",
          },

          {
            type: "number",
            condition: {
              field: "klettern",
              operator: "==",
              value: true,
            },
            rules: `required|max_value:${this.store.kids}`,
            name: "Klettern Teilnehmer (Kinder)",
            value: "klettern_kids",
            classes: "third",
          },

          {
            type: "time",
            condition: {
              field: "klettern",
              operator: "==",
              value: true,
            },
            name: "Von",
            value: "klettern_start",
            classes: "half",
          },

          {
            type: "time",
            condition: {
              field: "klettern",
              operator: "==",
              value: true,
            },
            name: "Bis",
            value: "klettern_end",
            classes: "half",
          },

          {
            type: "checkbox",
            name: "Bogenschießen",
            rules: "",
            value: "bogenschiessen",
            desc: "individuell",
          },

          {
            type: "time",
            condition: {
              field: "bogenschiessen",
              operator: "==",
              value: true,
            },
            name: "Von",
            value: "bogenschiessen_start",
            classes: "half",
          },

          {
            type: "time",
            condition: {
              field: "bogenschiessen",
              operator: "==",
              value: true,
            },
            name: "Bis",
            value: "bogenschiessen_end",
            classes: "half",
          },

          {
            type: "checkbox",
            name: "Volleyball",
            value: "volleyball",
            desc: "12€ / Platz",
          },

          {
            type: "time",
            condition: {
              field: "volleyball",
              operator: "==",
              value: true,
            },
            name: "Von",
            value: "volleyball_start",
            classes: "half",
          },

          {
            type: "time",
            condition: {
              field: "volleyball",
              operator: "==",
              value: true,
            },
            name: "Bis",
            value: "volleyball_end",
            classes: "half",
          },

          {
            type: "number",
            rules: "required|min_value:1",
            condition: {
              field: "volleyball",
              operator: "==",
              value: true,
            },
            name: "Anzahl Volleyballplätze",
            value: "volleyball_fields",
          },

          {
            type: "checkbox",
            name: "Minigolf",
            value: "minigolf",
            desc: "ab 2€ / Person",
          },

          {
            type: "info",
            condition: {
              field: "minigolf",
              operator: "==",
              value: true,
            },
            name: "minigolf-hinweis",
            value:
              "Minigolfanlage nicht fest buchbar, sondern Anlage je nach Wetter und Kapazität nutzbar.",
          },

          {
            type: "number",
            condition: {
              field: "minigolf",
              operator: "==",
              value: true,
            },
            rules: `required|max_value:${this.store.adults +
              this.store.reduced}`,
            name: "Minigolf Teilnehmer (Erwachsen)",
            value: "minigolf_adults",
            classes: "half",
          },

          {
            type: "number",
            condition: {
              field: "minigolf",
              operator: "==",
              value: true,
            },
            rules: `required|max_value:${this.store.kids}`,
            name: "Minigolf Teilnehmer (Kinder)",
            value: "minigolf_kids",
            classes: "half",
          },

          {
            type: "time",
            condition: {
              field: "minigolf",
              operator: "==",
              value: true,
            },
            name: "Von",
            value: "minigolf_start",
            classes: "half",
          },

          {
            type: "time",
            condition: {
              field: "minigolf",
              operator: "==",
              value: true,
            },
            name: "Bis",
            value: "minigolf_end",
            classes: "half",
          },

          {
            type: "checkbox",
            name: "Eigene Spiele auf der Wiese",
            value: "eigeneSpiele",
            desc: "kostenlos",
          },

          {
            type: "time",
            condition: {
              field: "eigeneSpiele",
              operator: "==",
              value: true,
            },
            name: "Von",
            value: "eigeneSpiele_start",
            classes: "half",
          },

          {
            type: "time",
            condition: {
              field: "eigeneSpiele",
              operator: "==",
              value: true,
            },
            name: "Bis",
            value: "eigeneSpiele_end",
            classes: "half",
          },

          {
            type: "checkbox",
            name: "Teambuilding",
            value: "teambuilding",
            desc: "individuell",
          },

          {
            type: "time",
            condition: {
              field: "teambuilding",
              operator: "==",
              value: true,
            },
            name: "Von",
            value: "teambuilding_start",
            classes: "half",
          },

          {
            type: "time",
            condition: {
              field: "teambuilding",
              operator: "==",
              value: true,
            },
            name: "Bis",
            value: "teambuilding_end",
            classes: "half",
          },
        ],
      };

      // 2 Aktivitäten & Bereiche
      let event_activities = {
        title: "Welche Aktivitäten wollt ihr buchen?",
        fields: [
          {
            type: "checkbox",
            name: "Klettern",
            rules: "",
            value: "klettern",
            desc: "ab 9€ / Person",
          },
          {
            type: "info",
            condition: {
              field: "klettern",
              operator: "==",
              value: true,
            },
            name: "Tipp:",
            value: "Gruppenrabatt ab 10 Teilnehmern",
          },
          {
            type: "number",
            condition: {
              field: "klettern",
              operator: "==",
              value: true,
            },
            rules: `required|max_value:${this.store.adults}`,
            name: "Klettern Teilnehmer (Erwachsen)",
            value: "klettern_adults",
            classes: "third",
          },
          {
            type: "number",
            condition: {
              field: "klettern",
              operator: "==",
              value: true,
            },
            rules: `required|max_value:${this.store.reduced}`,
            name: "Klettern Teilnehmer (Ermäßigt)",
            value: "klettern_reduced",
            classes: "third",
          },

          {
            type: "number",
            condition: {
              field: "klettern",
              operator: "==",
              value: true,
            },
            rules: `required|max_value:${this.store.kids}`,
            name: "Klettern Teilnehmer (Kinder)",
            value: "klettern_kids",
            classes: "third",
          },

          {
            type: "time",
            condition: {
              field: "klettern",
              operator: "==",
              value: true,
            },
            name: "Von",
            value: "klettern_start",
            classes: "half",
          },

          {
            type: "time",
            condition: {
              field: "klettern",
              operator: "==",
              value: true,
            },
            name: "Bis",
            value: "klettern_end",
            classes: "half",
          },

          {
            type: "checkbox",
            name: "Bogenschießen",
            rules: "",
            value: "bogenschiessen",
            desc: "individuell",
          },

          {
            type: "time",
            condition: {
              field: "bogenschiessen",
              operator: "==",
              value: true,
            },
            name: "Von",
            value: "bogenschiessen_start",
            classes: "half",
          },

          {
            type: "time",
            condition: {
              field: "bogenschiessen",
              operator: "==",
              value: true,
            },
            name: "Bis",
            value: "bogenschiessen_end",
            classes: "half",
          },

          {
            type: "checkbox",
            name: "Volleyball",
            value: "volleyball",
            desc: "12€ / Platz",
          },

          {
            type: "time",
            condition: {
              field: "volleyball",
              operator: "==",
              value: true,
            },
            name: "Von",
            value: "volleyball_start",
            classes: "half",
          },

          {
            type: "time",
            condition: {
              field: "volleyball",
              operator: "==",
              value: true,
            },
            name: "Bis",
            value: "volleyball_end",
            classes: "half",
          },

          {
            type: "number",
            rules: "required|min_value:1",
            condition: {
              field: "volleyball",
              operator: "==",
              value: true,
            },
            name: "Anzahl Volleyballplätze",
            value: "volleyball_fields",
          },

          {
            type: "checkbox",
            name: "Minigolf",
            value: "minigolf",
            desc: "ab 2€ / Person",
          },

          {
            type: "info",
            condition: {
              field: "minigolf",
              operator: "==",
              value: true,
            },
            name: "minigolf-hinweis",
            value:
              "Minigolfanlage nicht fest buchbar, sondern Anlage je nach Wetter und Kapazität nutzbar.",
          },

          {
            type: "number",
            condition: {
              field: "minigolf",
              operator: "==",
              value: true,
            },
            rules: `required|max_value:${this.store.adults +
              this.store.reduced}`,
            name: "Minigolf Teilnehmer (Erwachsen)",
            value: "minigolf_adults",
            classes: "half",
          },

          {
            type: "number",
            condition: {
              field: "minigolf",
              operator: "==",
              value: true,
            },
            rules: `required|max_value:${this.store.kids}`,
            name: "Minigolf Teilnehmer (Kinder)",
            value: "minigolf_kids",
            classes: "half",
          },

          {
            type: "time",
            condition: {
              field: "minigolf",
              operator: "==",
              value: true,
            },
            name: "Von",
            value: "minigolf_start",
            classes: "half",
          },

          {
            type: "time",
            condition: {
              field: "minigolf",
              operator: "==",
              value: true,
            },
            name: "Bis",
            value: "minigolf_end",
            classes: "half",
          },

          {
            type: "checkbox",
            name: "Teambuilding",
            value: "teambuilding",
            desc: "individuell",
          },

          {
            type: "time",
            condition: {
              field: "teambuilding",
              operator: "==",
              value: true,
            },
            name: "Von",
            value: "teambuilding_start",
            classes: "half",
          },

          {
            type: "time",
            condition: {
              field: "teambuilding",
              operator: "==",
              value: true,
            },
            name: "Bis",
            value: "teambuilding_end",
            classes: "half",
          },
        ],
      };

      let event_personal = {
        title: "Deine Kontaktdaten",
        fields: [
          {
            type: "text",
            name: "Name",
            rules: "required",
            value: "name",
          },
          {
            type: "text",
            name: "Telefon",
            rules: "required",
            value: "phone",
          },
          {
            type: "text",
            name: "E-Mail",
            rules: "required|email",
            value: "email",
          },
          {
            type: "review",
          },
        ],
      };

      //Build actual route
      let default_event = [
        event_type,
        event_visitor,
        event_areas,
        event_activities_companion,
        event_personal,
      ];

      let school_event = [
        event_type,
        event_visitor_2inkl,
        event_areas,
        event_activities_companion,
        event_personal,
      ];

      let company_event = [
        event_type,
        event_visitor,
        event_areas_company,
        event_activities,
        event_personal,
      ];

      let birthday_event = [
        event_type,
        event_visitor_companion,
        event_kidsmneu,
        event_activities_companion,
        event_personal,
      ];

      let route = default_event;
      switch (this.store.event_type) {
        case "Private Feier":
          route = default_event;
          break;
        case "Schulklassen":
          route = school_event;
          break;
        case "Vereine":
          route = default_event;
          break;
        case "Junggesellenabschiede":
          route = default_event;
          break;
        case "Kindergeburtstag":
          route = birthday_event;
          break;
        case "Firmen-Event":
          route = company_event;
          break;
      }

      return route;
    },
    currentFields() {
      let fields = [];

      this.currentStep.fields.forEach((field) => {
        if (field.condition === undefined) {
          fields.push(field);
        } else {
          let compare =
            typeof field.condition.field === "string"
              ? this.$data["store"][field.condition.field]
              : field.condition.field;
          let build_cond = false;
          switch (field.condition.operator) {
            case ">":
              build_cond = compare > field.condition.value;
              break;
            case "<":
              build_cond = compare < field.condition.value;
              break;
            case "==":
              build_cond = compare == field.condition.value;
              break;
            case "===":
              build_cond = compare == field.condition.value;
              break;
            case "!=":
              build_cond = compare != field.condition.value;
              break;
            case ">=":
              build_cond = compare >= field.condition.value;
              break;
            case "<=":
              build_cond = compare <= field.condition.value;
          }
          if (build_cond) fields.push(field);
        }
      });
      return fields;
    },
    date_formated() {
      return moment(this.store.date, "X").format("D.M.YYYY");
    },
    time_formated() {
      return moment(this.store.date, "X").format("HH:mm");
    },
    currentStep() {
      return this.route[this.activeStep];
    },
    isLastStep() {
      return this.activeStep === this.route.length - 1;
    },
    kids() {
      return isNaN(parseFloat(this.store.kids)) ? 0 : this.store.kids;
    },
    adults() {
      return isNaN(parseFloat(this.store.adults)) ? 0 : this.store.adults;
    },
    reduced() {
      return isNaN(parseFloat(this.store.reduced)) ? 0 : this.store.reduced;
    },
    persons() {
      const all =
        parseInt(this.adults) + parseInt(this.kids) + parseInt(this.reduced);
      return isNaN(all) ? 0 : all;
    },
    klettern_price() {
      let price = {
        all: 0,
        kids: 0,
        adults: 0,
      };
      if (this.store.klettern) {
        let discount =
          parseInt(this.store.klettern_reduced) +
            parseInt(this.store.klettern_adults) +
            parseInt(this.store.klettern_kids) >=
          10;
        price.kids = !discount
          ? this.store.klettern_kids * this.fee.klettern.kids.regular
          : this.store.klettern_kids * this.fee.klettern.kids.discount;
        price.adults = !discount
          ? this.store.klettern_adults * this.fee.klettern.adults.regular
          : this.store.klettern_adults * this.fee.klettern.adults.discount;
        price.reduced = !discount
          ? this.store.klettern_reduced * this.fee.klettern.reduced.regular
          : this.store.klettern_reduced * this.fee.klettern.reduced.discount;
        price.all = price.reduced + price.adults + price.kids;
      }
      return price;
    },
    minigolf_price() {
      let price = {
        all: 0,
        kids: 0,
        adults: 0,
      };
      if (this.store.minigolf) {
        let discount =
          parseInt(this.store.minigolf_adults) +
            parseInt(this.store.minigolf_kids) >=
          10;
        price.kids = !discount
          ? this.store.minigolf_kids * this.fee.minigolf.kids.regular
          : this.store.minigolf_kids * this.fee.minigolf.kids.discount;
        price.adults = !discount
          ? this.store.minigolf_adults * this.fee.minigolf.adults.regular
          : this.store.minigolf_adults * this.fee.minigolf.adults.discount;
        price.all = price.adults + price.kids;
      }
      return price;
    },
    volleyball_price() {
      return this.store.volleyball
        ? this.fee.volleyball.regular * parseInt(this.store.volleyball_fields)
        : 0;
    },
    kids_menu_price() {
      let price = 0;
      if (
        this.store.kids_menu_type != "Kein Menü" &&
        this.store.event_type == "Kindergeburtstag"
      ) {
        price = this.fee.kids_menu.regular * this.store.kids_menu;
      }
      return price;
    },
    bbq_price() {
      let price = 0;
      if (this.store.bbq) {
        const catering_price = this.store.catering_special
          ? this.fee.bbq.catering.special
          : this.fee.bbq.catering.regular;

        if (this.store.bbq_menu == "Catering") {
          price = this.persons * catering_price;
        } else if (this.store.bbq_menu == "Selbst grillen") {
          price =
            this.fee.bbq.selfservice.rent + this.fee.bbq.selfservice.drinks;
        }
      }
      return price;
    },
    price() {
      let price = { adults: 0, kids: 0, reduced: 0, all: 0 };

      // Catering Entry inklsuive!
      if (!(this.store.bbq && this.store.bbq_menu == "Catering")) {
        console.log("what", this.adults);
        let adults = this.adults;
        let kids = this.kids;
        let reduced = this.reduced;
        let group_discount = this.persons >= 20;

        //Known Issue:
        // Free Teacher Pays entry fee

        // Do we have inclusive entries?
        if (this.store.event_type == "Schulklassen") {
          adults = adults - 2;
        }

        // cliimbing adults included
        if (this.store.klettern) {
          adults = adults - this.store.klettern_adults;
          kids = kids - this.store.klettern_kids;
          reduced = reduced - this.store.klettern_reduced;
        }

        price = {
          adults:
            Math.max(0, adults) *
            (group_discount
              ? this.fee.adults.discount
              : this.fee.adults.regular),
          kids:
            Math.max(0, kids) *
            (group_discount ? this.fee.kids.discount : this.fee.kids.regular),
          reduced:
            Math.max(0, reduced) *
            (group_discount
              ? this.fee.reduced.discount
              : this.fee.reduced.regular),
        };
      }

      let entry = price.adults + price.reduced + price.kids;

      price.all =
        entry +
        this.kids_menu_price +
        this.bbq_price +
        this.volleyball_price +
        this.minigolf_price.all +
        this.klettern_price.all;

      return price;
    },
  },
  methods: {
    getDisabledDates() {
      this.axios
        .get("https://event.bitpunk.de/wp-json/wp/v2/sea_dates?_fields=acf")
        .then((response) => {
          response.data.forEach((item) => {
            console.log(item.acf["event-date"]);
            const date = moment(item.acf["event-date"], "YYYYMMDD").format(
              "YYYY-MM-DD"
            );
            if (date !== "Invalid date") {
              this.dateConfig.disable.push(date);
            }
          });
        });
    },
    increase(field_name) {
      this.$data["store"][field_name]++;
    },
    decrease(field_name) {
      let value = this.$data["store"][field_name];
      0 < value
        ? this.$data["store"][field_name]--
        : this.$data["store"][field_name];
    },
    submit() {
      const payload = {
        price: this.price,
        ...this.store,
      };
      this.isSending = true;
      //Post
      this.axios
        .post("mail.php", JSON.stringify(payload))
        .then((res) => {
          console.log(res);
          this.isSending = false;
          this.isDone = true;
        })
        .catch((error) => {
          console.log(error);
          this.isSending = false;
        });
    },
    nextStep() {
      if (this.isLastStep) {
        return this.submit();
      }
      this.activeStep++;
    },
  },
};
</script>

<style lang="postcss">
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");

* {
  box-sizing: border-box;
}

.flatpickr-calendar {
  font-family: "Roboto", sans-serif;
}

.flatpickr-day.selected {
  background: #472318;
  border-color: #472318;
  &:hover {
    border-color: #472318;
    background: #472318;
  }
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.btn {
  border-radius: 0.25em;
  background: #472318;
  color: #fff;
  padding: 1em 1.5em;
  border: none;
  font-size: 1em;
  cursor: pointer;
  display: inline-block;
  text-decoration: none;

  &:hover {
    transform: scale(1.02);
    transition: transform 0.25s ease-in;
  }

  &:active {
    transform: scale(0.95);
    transition: none;
  }
}

.btn.ghost {
  background: none;
  color: inherit;
}

#app {
  font-family: "Roboto", sans-serif;
  color: #472318;
  min-height: 100vh;
  padding: 0 0 4em 0;
}

.label {
  display: block;
  font-size: 0.8em;
  padding-bottom: 0.25em;
}

.success {
  padding: 2em;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.success__inner {
  text-align: center;
}

.planer {
  border-radius: 0.5em;
  max-width: 50em;
  margin: auto;
  background: #fff;
  padding: 1em;
  box-shadow: 0 0.5em 1em -0.5em rgba(0, 0, 0, 0.25);
}

.header {
  padding: 1em;
  right: 0;
  top: 0;
  left: 0;
}

.header__stepper {
  max-width: 30em;
  padding: 1em;
  margin: auto;
}

.footer {
  bottom: 0;
  left: 0;
  right: 0;
  position: fixed;
  background: rgba(255, 255, 255, 0.8);
  box-shadow: 0 0 1px rgba(0, 0, 0, 0.25);
}

.footer__price {
  font-size: 1.5em;
  color: #e1b143;
}

.footer__inner {
  max-width: 40em;
  display: flex;
  padding: 1em 2.5em;
  align-items: center;
  justify-content: space-between;
  margin: auto;
}

.step {
  padding: 1em;
}

.fields {
  display: flex;
  flex-wrap: wrap;
}

.field {
  display: block;
  padding: 0 0.5em;
  margin: 0.5em 0;
  width: 100%;

  @media (min-width: 35em) {
    padding: 0 1em;
    margin: 1em 0;
    &.half {
      width: 50%;
    }

    &.third {
      width: 33.33%;
    }
  }
}

.radios {
  display: flex;
  flex-wrap: wrap;
}

.radio {
  margin-bottom: 0.5em;
  display: block;
  width: 100%;
  transition: all 0.125s ease-in;

  &:hover {
    transition: all 0.125s ease-in;
    transform: scale(1.01);
  }

  &:active {
    transform: scale(0.99);
  }
}

.radio input,
.checkbox input {
  display: none;
}

.text {
  padding: 1.5em;
  font-size: 1em;
  border: solid 1px;
  border-radius: 0.25em;
  width: 100%;
}

.number {
  padding: 1.5em;
  font-size: 1em;
  border: solid 1px;
  width: 100%;
  appearance: none;
  -moz-appearance: textfield;
}

.number__inner {
  display: flex;
}

.number__btn {
  appearance: none;
  border: none;
  padding: 0.5em;
  font-size: 1.5em;
  cursor: pointer;
  color: #fff;
  background: #472318;
  width: 2em;

  &:first-child {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  &:last-child {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.radio__inner {
  padding: 1.5em;
  font-size: 1em;
  display: block;
  cursor: pointer;
  //background-color: #fffbf3;
  border-radius: 0.25em;
  border: solid 1px;
  display: flex;
  align-items: center;
  transition: all 0.25s ease-in;

  &:before {
    color: inherit;
    content: "";
    display: block;
    width: 1.5em;
    height: 1.5em;
    box-shadow: 0 0 0 3px white;
    margin-right: 0.75em;
    border: solid 2px;
    border-radius: 999px;
  }
}

.radio__desc {
  font-size: 0.75em;
  display: block;
  margin-left: auto;
  padding-left: 1em;
}

.radio input:checked ~ .radio__inner {
  background: #e1b143;
  box-shadow: none;
  border-color: #e1b143;

  &:before {
    background-size: contain;
    background-color: #472318;
    background-image: url("assets/check.svg");
  }
}

.checkbox {
  margin-bottom: 0.5em;
  display: block;
  width: 100%;
  transition: all 0.125s ease-in;

  &:hover {
    transition: all 0.125s ease-in;
    transform: scale(1.01);
  }
  &:active {
    transform: scale(0.99);
  }
}

.checkbox__inner {
  padding: 1.5em;
  font-size: 1em;
  display: block;
  cursor: pointer;
  border-radius: 0.25em;
  display: flex;
  align-items: center;
  transition: all 0.25s ease-in;

  &:before {
    color: inherit;
    content: "";
    display: block;
    width: 1.5em;
    height: 1.5em;
    border: solid 2px;
    border-radius: 0.25em;
    box-shadow: 0 0 0 3px white;
    margin-right: 0.75em;
    border: solid 2px;
  }
}

.checkbox__desc {
  font-size: 0.75em;
  display: block;
  margin-left: auto;
  padding-left: 1em;
}

.checkbox input:checked ~ .checkbox__inner {
  background-color: #e1b143;
  box-shadow: none;
  border-color: #e1b143;

  &:before {
    background-size: contain;
    background-color: #472318;
    background-image: url("assets/check.svg");
  }
}

.info {
  background: #65b96f;
  padding: 1em;
  color: #fff;
  border-radius: 0.25em;

  & p {
    margin: 0;
  }
}

.review {
  color: #fff;
  background: #6c4f46;
  border-radius: 0.5em;
  margin-top: 1em;
  padding: 1em 2em;
}

.review__title {
  color: #e1b143;
}

.table {
  margin-bottom: 3em;
  width: 100%;
  font-size: 0.8em;

  & td,
  & th {
    font-weight: normal;
    padding: 0.5em;
    text-align: left;
    border-bottom: solid 1px #472318;
  }

  & s {
    color: red;
    font-size: 0.8em;
  }

  & td:last-child,
  & th:last-child {
    text-align: right;
  }

  @media (min-width: 40em) {
    font-size: 1em;
  }
}

.error {
  color: #ea563d;
  padding: 0.5em 0;
  display: inline-block;
}

.list-item {
  display: inline-block;
}
.list-enter-active,
.list-leave-active {
  transition: all 0.5s;
}
.list-enter {
  opacity: 0;
  transform: translateY(30px);
}
.list-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}
</style>
